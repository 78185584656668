  #owl-demo .item {
    margin: 0;
  }

  #owl-demo .item img {
    display: block;
    width: 100%;
    height: auto;
  }

  .owl-carousel .owl-wrapper:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
  }

  /* display none until init */

  .owl-carousel {
    /* display: none; */
    position: relative;
    width: 100%;
    height: 100%;   
    -ms-touch-action: pan-y;
    display: flex;
  }

  .owl-carousel .owl-wrapper {
    display: none;
    position: relative;
    -webkit-transform: translate3d(0px, 0px, 0px);
  }

  .owl-carousel .owl-wrapper-outer {
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  .owl-carousel .owl-wrapper-outer.autoHeight {
    -webkit-transition: height 500ms ease-in-out;
    -moz-transition: height 500ms ease-in-out;
    -ms-transition: height 500ms ease-in-out;
    -o-transition: height 500ms ease-in-out;
    transition: height 500ms ease-in-out;
  }

  .owl-carousel .owl-item {
    float: left;
  }

  .owl-controls .owl-page, .owl-controls .owl-buttons div {
    cursor: pointer;
  }

  .owl-controls {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  /* mouse grab icon */

  .grabbing {
    /* cursor: url("../images/grabbing.png") 8 8, move; */
  }

  /* fix */

  .owl-carousel .owl-wrapper, .owl-carousel .owl-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
  }

  .owl-theme .owl-controls {
    margin-top: 10px;
    text-align: center;
  }

  /* Styling Next and Prev buttons */

  .owl-theme .owl-controls .owl-buttons div {
    color: #FFF;
    display: inline-block;
    zoom: 1;
    *display: inline;
    /*IE7 life-saver */
    margin: 0;
    padding: 3px 10px;
    font-size: 12px;
    height: 100%;
    width: 150px;
    position: absolute;
    bottom: 0;
    filter: Alpha(Opacity=50);
    /*IE7 fix*/
    opacity: 0.5;
    z-index: 5;
  }

  .owl-next {
    right: 0;
    background-image: -webkit-gradient(linear, 0% top, 100% top, from(rgba(0, 0, 0, 0.0001)), to(rgba(0, 0, 0, 0.5)));
    background-image: -webkit-linear-gradient(left, color-stop(rgba(0, 0, 0, 0.0001) 0), color-stop(rgba(0, 0, 0, 0.5) 100%));
    background-image: -moz-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0, rgba(0, 0, 0, 0.5) 100%);
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.0001) 0, rgba(0, 0, 0, 0.5) 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1);
  }

  .owl-prev {
    left: 0;
    background-image: -webkit-gradient(linear, 0% top, 100% top, from(rgba(0, 0, 0, 0.0001)), to(rgba(0, 0, 0, 0.5)));
    background-image: -webkit-linear-gradient(right, color-stop(rgba(0, 0, 0, 0.0001) 0), color-stop(rgba(0, 0, 0, 0.5) 100%));
    background-image: -moz-linear-gradient(right, rgba(0, 0, 0, 0.0001) 0, rgba(0, 0, 0, 0.5) 100%);
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0.0001) 0, rgba(0, 0, 0, 0.5) 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1)
  }

  /* Clickable class fix problem with hover on touch devices */

  /* Use it for non-touch hover action */

  .owl-theme .owl-controls.clickable .owl-buttons div:hover {
    filter: Alpha(Opacity=100);
    /*IE7 fix*/
    opacity: 1;
    text-decoration: none;
  }

  /* Styling Pagination*/

  .owl-theme .owl-controls .owl-page {
    display: inline-block;
    zoom: 1;
    *display: inline;
    /*IE7 life-saver */
  }

  .owl-theme .owl-controls .owl-page span {
    display: block;
    width: 10px;
    height: 10px;
    margin: 5px 3px;
    border: 1px solid #8D9198;
  }

  .owl-pagination {
    top: -40px;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
  }

  .owl-theme .owl-controls .owl-page.active span, .owl-theme .owl-controls.clickable .owl-page:hover span {
    background: #8D9198;
  }

  /* If PaginationNumbers is true */

  .owl-theme .owl-controls .owl-page span.owl-numbers {
    height: auto;
    width: auto;
    color: #FFF;
    padding: 2px 10px;
    font-size: 12px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
  }

  /* preloading images */

  .owl-item.loading {
    min-height: 150px;
    /* background: url("../images/AjaxLoader.gif") no-repeat center center */
  }

  .owl-origin {
    -webkit-perspective: 1200px;
    -webkit-perspective-origin-x: 50%;
    -webkit-perspective-origin-y: 50%;
    -moz-perspective: 1200px;
    -moz-perspective-origin-x: 50%;
    -moz-perspective-origin-y: 50%;
    perspective: 1200px;
  }

  /* fade */

  .owl-fade-out {
    z-index: 10;
    -webkit-animation: fadeOut .7s both ease;
    -moz-animation: fadeOut .7s both ease;
    animation: fadeOut .7s both ease;
  }

  .owl-fade-in {
    -webkit-animation: fadeIn .7s both ease;
    -moz-animation: fadeIn .7s both ease;
    animation: fadeIn .7s both ease;
  }

  /* backSlide */

  .owl-backSlide-out {
    -webkit-animation: backSlideOut 1s both ease;
    -moz-animation: backSlideOut 1s both ease;
    animation: backSlideOut 1s both ease;
  }

  .owl-backSlide-in {
    -webkit-animation: backSlideIn 1s both ease;
    -moz-animation: backSlideIn 1s both ease;
    animation: backSlideIn 1s both ease;
  }

  /* goDown */

  .owl-goDown-out {
    -webkit-animation: scaleToFade .7s ease both;
    -moz-animation: scaleToFade .7s ease both;
    animation: scaleToFade .7s ease both;
  }

  .owl-goDown-in {
    -webkit-animation: goDown .6s ease both;
    -moz-animation: goDown .6s ease both;
    animation: goDown .6s ease both;
  }

  /* scaleUp */

  .owl-fadeUp-in {
    -webkit-animation: scaleUpFrom .5s ease both;
    -moz-animation: scaleUpFrom .5s ease both;
    animation: scaleUpFrom .5s ease both;
  }

  .owl-fadeUp-out {
    -webkit-animation: scaleUpTo .5s ease both;
    -moz-animation: scaleUpTo .5s ease both;
    animation: scaleUpTo .5s ease both;
  }

  /* Keyframes */

  /*empty*/

  @-webkit-keyframes empty {
    0% {
      opacity: 1
    }
  }

  @-moz-keyframes empty {
    0% {
      opacity: 1
    }
  }

  @keyframes empty {
    0% {
      opacity: 1
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @-moz-keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @-webkit-keyframes backSlideOut {
    25% {
      opacity: .5;
      -webkit-transform: translateZ(-500px);
    }
    75% {
      opacity: .5;
      -webkit-transform: translateZ(-500px) translateX(-200%);
    }
    100% {
      opacity: .5;
      -webkit-transform: translateZ(-500px) translateX(-200%);
    }
  }

  @-moz-keyframes backSlideOut {
    25% {
      opacity: .5;
      -moz-transform: translateZ(-500px);
    }
    75% {
      opacity: .5;
      -moz-transform: translateZ(-500px) translateX(-200%);
    }
    100% {
      opacity: .5;
      -moz-transform: translateZ(-500px) translateX(-200%);
    }
  }

  @keyframes backSlideOut {
    25% {
      opacity: .5;
      transform: translateZ(-500px);
    }
    75% {
      opacity: .5;
      transform: translateZ(-500px) translateX(-200%);
    }
    100% {
      opacity: .5;
      transform: translateZ(-500px) translateX(-200%);
    }
  }

  @-webkit-keyframes backSlideIn {
    0%, 25% {
      opacity: .5;
      -webkit-transform: translateZ(-500px) translateX(200%);
    }
    75% {
      opacity: .5;
      -webkit-transform: translateZ(-500px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateZ(0) translateX(0);
    }
  }

  @-moz-keyframes backSlideIn {
    0%, 25% {
      opacity: .5;
      -moz-transform: translateZ(-500px) translateX(200%);
    }
    75% {
      opacity: .5;
      -moz-transform: translateZ(-500px);
    }
    100% {
      opacity: 1;
      -moz-transform: translateZ(0) translateX(0);
    }
  }

  @keyframes backSlideIn {
    0%, 25% {
      opacity: .5;
      transform: translateZ(-500px) translateX(200%);
    }
    75% {
      opacity: .5;
      transform: translateZ(-500px);
    }
    100% {
      opacity: 1;
      transform: translateZ(0) translateX(0);
    }
  }

  @-webkit-keyframes scaleToFade {
    to {
      opacity: 0;
      -webkit-transform: scale(.8);
    }
  }

  @-moz-keyframes scaleToFade {
    to {
      opacity: 0;
      -moz-transform: scale(.8);
    }
  }

  @keyframes scaleToFade {
    to {
      opacity: 0;
      transform: scale(.8);
    }
  }

  @-webkit-keyframes goDown {
    from {
      -webkit-transform: translateY(-100%);
    }
  }

  @-moz-keyframes goDown {
    from {
      -moz-transform: translateY(-100%);
    }
  }

  @keyframes goDown {
    from {
      transform: translateY(-100%);
    }
  }

  @-webkit-keyframes scaleUpFrom {
    from {
      opacity: 0;
      -webkit-transform: scale(1.5);
    }
  }

  @-moz-keyframes scaleUpFrom {
    from {
      opacity: 0;
      -moz-transform: scale(1.5);
    }
  }

  @keyframes scaleUpFrom {
    from {
      opacity: 0;
      transform: scale(1.5);
    }
  }

  @-webkit-keyframes scaleUpTo {
    to {
      opacity: 0;
      -webkit-transform: scale(1.5);
    }
  }

  @-moz-keyframes scaleUpTo {
    to {
      opacity: 0;
      -moz-transform: scale(1.5);
    }
  }

  @keyframes scaleUpTo {
    to {
      opacity: 0;
      transform: scale(1.5);
    }
  }
