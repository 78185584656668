/**
* Template Name: MaxiBiz
* Template URL: https://templatemag.com/maxibiz-bootstrap-business-template/
* Author: TemplateMag.com
* License: https://templatemag.com/license/
*/

@import url("https://fonts.googleapis.com/css?family=Ruda:400,900,700");

* {
  margin: 0;
  padding: 0px;
  font-family: "Ruda", sans-serif;
}

body {
  background: #f8f8f8;
  margin: 0;
  height: 100%;
  z-index: 1000;
  color: #696e74;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Ruda", sans-serif;
  font-weight: 700;
}

p {
  padding: 0;
  margin-bottom: 12px;
  font-family: "Ruda", sans-serif !important;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  color: #696e74;
  margin-top: 10px;
}

html,
body {
  height: 100%;
}

div.body {
  height: 100%;
  width: 100%;
}

img {
  height: auto;
  max-width: 100%;
}

.hidden {
  display: none;
}

.alignleft {
  float: left;
}

.alignright {
  float: right;
}

.aligncenter {
  margin-left: auto;
  margin-right: auto;
  display: block;
  clear: both;
}

.clear {
  clear: both;
  display: block;
  font-size: 0;
  height: 0;
  line-height: 0;
  width: 100%;
}

::-moz-selection {
  color: #fff;
  text-shadow: none;
  background: #2b2e31;
}

::selection {
  color: #fff;
  text-shadow: none;
  background: #2b2e31;
}

*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

a {
  padding: 0;
  margin: 0;
  text-decoration: none;
  -webkit-transition: background-color 0.4s linear, color 0.4s linear;
  -moz-transition: background-color 0.4s linear, color 0.4s linear;
  -o-transition: background-color 0.4s linear, color 0.4s linear;
  -ms-transition: background-color 0.4s linear, color 0.4s linear;
  transition: background-color 0.4s linear, color 0.4s linear;
}

a:hover,
a:focus {
  text-decoration: none;
  color: #696e74;
}

a img {
  border: none;
  vertical-align: bottom;
}

a.remove {
  display: block;
  height: 18px;
  width: 18px;
  margin: 10px;
  padding-left: 0 !important;
  text-indent: -9999px;
  /* background: url("img/remove.png") #798184; */
  border-radius: 100px;
  border-style: solid;
  border-width: 1px;
}

p,
pre,
ul,
ol,
dl,
dd,
blockquote,
address,
table,
fieldset,
form {
  margin-bottom: 20px;
}

a img {
  border: none;
}

ul,
ol,
dl {
  margin-left: 30px;
}

ul,
ol,
dl {
  margin-bottom: 1.25em;
  font-family: inherit;
}

ul,
ol {
  margin-left: 0;
}

ul.no-bullet,
ol.no-bullet {
  margin-left: 0;
}

ul li ul,
ul li ol {
  margin-left: 1.25em;
  margin-bottom: 0;
}

ol li ul,
ol li ol {
  margin-left: 1.25em;
  margin-bottom: 0;
}

dl dt {
  margin-bottom: 0.3em;
  font-weight: bold;
}

dl dd {
  margin-bottom: 0.75em;
}

.lead {
  font-size: 21px;
  padding: 15px 0;
  font-weight: 400;
}

section::before,
section::after {
  position: absolute;
  content: "";
  pointer-post-wraps: none;
}

.first {
  clear: both;
}

.last {
  margin-right: 0;
}

/*Bootstrap Modifications*/

.nav-tabs > li > a {
  margin-right: 2px;
  line-height: 1.42857143;
  border: 1px solid transparent;
  border-radius: 0 0 0 0;
  background: #eee;
}

input,
button,
select,
textarea {
  margin-bottom: 10px;
}

.accordion-heading .accordion-toggle {
  display: block;
  cursor: pointer;
  border-top: 1px solid #f5f5f5;
  padding: 5px 0px;
  line-height: 28.75px;
  text-transform: uppercase;
  color: #1a1a1a;
  background-color: #ffffff;
  outline: none !important;
  text-decoration: none;
}

.icon-fixed-width {
  display: inline-block;
  width: 1.1428571428571428em;
  text-align: right;
  padding-right: 10px;
}

/*	################################################################
	2. SITE STRUCTURES
################################################################# */

.quote-post {
  position: relative;
  background: #f5f5f5;
  border: 1px solid #eeeeee;
  padding: 20px 10px;
}

.quote-post:after {
  font-family: "FontAwesome";
  content: "\f10e";
  padding-right: 35px;
  right: 0;
  position: absolute;
  top: 0;
  font-size: 600%;
  opacity: 0.2;
  -moz-opacity: 0.2;
  filter: alpha(opacity=20);
}

.quote-post blockquote {
  border-left: 0px solid #fff;
}

.author_box {
  background: #f5f5f5;
  border: 1px solid #eeeeee;
  padding: 20px;
}

.author_box .social_buttons a {
  color: #2b2e31;
  font-size: 18px;
}

.author_box img {
  margin-right: 20px;
  width: 100px;
  height: 100px;
}

.blog-wrap {
  background: #ffffff;
  padding: 25px 0;
  position: relative;
  display: block;
  z-index: 1;
}

.blog-wrap .blog-media {
  margin: 20px 0;
}

.post-desc {
  padding: 0;
}

.author-wrap {
  position: relative;
  z-index: 1;
  margin: -70px auto 0;
  width: 100px;
}

.page-header {
  border-bottom: 1px solid #eeeeee;
  margin: 0 0;
  padding-bottom: 0;
}

.author-wrap > .inside img {
  background: #fff;
  border: 2px solid #eeeeee;
  display: block;
  margin: 0 auto;
  width: 100px;
  height: 100px;
  padding: 3px;
  position: relative;
  text-align: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}

.blog-title h2 {
}

.blog-title .post-meta {
}

.post-meta a,
.post-meta span {
}

.post-meta .sep {
  color: #777777;
  padding: 0 10px 0 10px;
}

.post-meta a:hover {
  border-bottom: 1px dotted #14ddb3;
}

#comments_wrapper {
  margin: 60px 0;
}

.comment-list {
  margin: 60px 0;
  list-style: none;
}

.comment-list ul li,
.comment-list ol li {
  list-style: none;
}

.comment-avatar {
  margin: 0 20px 0 0;
  float: left;
  height: 70px;
  width: 70px;
}

.comment-content {
  background: #f5f5f5;
  border: 1px solid #eeeeee;
  margin-bottom: 20px;
  overflow: hidden;
  padding: 20px;
}

.comment-author {
  margin-bottom: 10px;
}

.comment-meta {
  font-size: 12px;
  color: #aaa;
  padding-left: 10px;
}

.comment-reply {
  float: right;
  font-size: 12px;
  color: #aaa;
}

.leave-comment textarea {
  height: 150px;
}

#wrapper {
  padding: 20px;
  background: #ffffff;
  margin: 40px auto;
  border: 1px solid #dcdbd7;
}

#wrapper .section1 {
  box-shadow: none;
  padding-left: 5px;
  padding-right: 5px;
}

#wrapper .footer {
  box-shadow: none;
  padding: 25px 20px 0;
}

#wrapper .section3 {
  border: 0px solid #dcdbd7;
  padding: 0 20px;
}

body.nobg {
  padding: 0px !important;
  margin: 0;
  overflow: hidden;
  width: 100%;
  background: #2b2e31;
  height: 100%;
}

.notfound h2 {
  color: #2b2e31 !important;
}

.notfound {
  padding: 0px !important;
  margin: 0;
  overflow: hidden;
  width: 100%;
  /* background: url("../img/404.png") no-repeat top right #ffffff; */
  min-height: 400px;
}

.notfound .small-title {
  margin: 30px 0;
  width: 60%;
  font-weight: 300;
  line-height: 1.5;
}

body.nobg #layerslider * {
  font-weight: 300;
}

body.nobg #layerslider h1 {
  font-size: 30px;
  color: #eee;
}

body.nobg #layerslider {
  position: absolute;
  z-index: 1;
}

body.nobg .section1 {
  background: rgba(0, 0, 0, 0.4);
  z-index: 2;
  position: relative;
  top: 20%;
}

#market-wrapper,
.market-call {
  background: #dadad2;
}

#market-wrapper h1 {
  color: #ffffff;
  padding: 40px 0 0;
}

#market-wrapper .mbuttons {
  margin: 20px 0 0;
}

#intro {
  background: url(../img/intro-bg.jpg) bottom center no-repeat fixed;
  background-size: cover;
  padding: 80px 0;
  text-align: center;
}

#intro h1 {
  color: #ffffff;
  padding: 0;
  margin: 0 0 20px 0;
  font-size: 42px;
}

#intro p {
  color: #ffffff;
  font-size: 20px;
  line-height: 28px;
  padding: 0;
  margin: 0;
}

@media (max-width: 768px) {
  #intro {
    background-attachment: scroll;
  }

  #intro h1 {
    font-size: 28px;
  }

  #intro p {
    font-size: 15px;
  }
}
.post-wrapper-top {
  background-color: #f5f5f5;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 0;
  border-bottom: 3px solid #dcdbd7;
  margin-bottom: 0;
  padding: 0;
  position: relative;
  text-align: left;
}

.post-wrapper-top .container {
}

.breadcrumb > li + li:before {
  color: #798184;
  font-size: 11px;
}

.post-wrapper-top h2 {
  border-bottom: 3px solid #fff;
  display: inline-block;
  font-weight: 500;
  font-size: 1.8em;
  padding: 0 0 20px;
  color: #2b2e31;
  position: relative;
  margin-bottom: -3px;
}

.breadcrumb {
  background-color: rgba(0, 0, 0, 0);
  border-left: 0 solid #63b76c;
  border-radius: 0;
  font-size: 11px;
  font-style: italic;
  color: #798184;
  font-family: "Ruda", sans-serif;
  font-weight: 300;
  list-style: none outside none;
  margin: 20px 0 0;
  padding: 0;
}

/* search */

.search-bar {
  padding: 0;
  width: 190px;
  height: 40px;
  float: right;
  position: relative;
  display: inline-block;
  /* background: url("../img/search.png") left top no-repeat; */
  margin-top: 60px;
}

.search-bar form input {
  width: 190px !important;
  padding: 0 0 0 35px !important;
  margin: -12px 0 0 0 !important;
  border: 0 !important;
  outline: 0 !important;
  height: 40px !important;
  float: left !important;
  display: inline !important;
  font-family: "Ruda", sans-serif !important;
  font-weight: normal !important;
  font-style: italic !important;
  font-size: 11px !important;
  color: #798184 !important;
  background: transparent !important;
}

.search-bar form input.searchsubmit {
  width: 25px !important;
  height: 15px !important;
  padding: 0 !important;
  float: left !important;
  display: inline !important;
}

.search-bar form input:focus,
.search-bar form input {
  border: 0;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

#contact .form-control {
  margin-bottom: 10px;
}

#contact label,
#contact .form-verif {
  width: 45px;
  line-height: 45px;
  float: left;
  margin-right: 10px;
}

#contact fieldset {
  padding: 20px;
  border: 1px solid #eee;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
}

#contact legend {
  padding: 7px 10px;
  font-weight: bold;
  color: #000;
  border: 1px solid #eee;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  margin-bottom: 0 !important;
  margin-bottom: 20px;
}

#contact span.required {
  font-size: 13px;
  color: #ff0000;
}

#message {
  margin: 10px 0;
  padding: 0;
}

.error_message {
  display: block;
  height: 22px;
  line-height: 22px;
  /* background: #FBE3E4 url("../img/error.gif") no-repeat 10px center; */
  padding: 3px 10px 3px 35px;
  color: #8a1f11;
  border: 1px solid #fbc2c4;
  -webkit-border-radius: 5px;
}

.loader {
  padding: 0 10px;
}

#success_page h1 {
  background: url("../img/success.gif") left no-repeat;
  padding-left: 22px;
  font-size: 21px;
}

#contact acronym {
  border-bottom: 1px dotted #ccc;
}

.topbar {
  background: #3d68b2 !important                                                                                                                                                                                                ;
  color: #ffffff;
}

.top-bar {
  background: #ffffff;
  height: 45px;
  line-height: 45px;
  margin-top: 30px;
  position: relative;
}

.top-bar-section a {
  background: #ffffff !important;
  text-transform: uppercase;
}

.top-bar-section .dropdown {
  /* border-top: 2px solid #000 */
}

.top-bar-section .dropdown li a {
  color: #696e74;
  font-size: 13px;
  text-transform: none;
  border-top: none;
}

.top-bar-section ul li.active > a,
.top-bar-section ul li > a {
  color: #2b2e31;
  font-weight: 400;
}
.header-login {
  /* background: #facf0f!important; */
  background: url(../img/bg-illust.png) right center no-repeat #facf0f;
  background-size: auto;
  background-clip: content-box;
  display: block;
  border-bottom: 1px solid #dcdbd7;
  position: relative;
  z-index: 99;
  box-shadow: inset 0 -10px 15px rgba(0 0 0 / 25%);
}

.header {
  /* background: #facf0f!important; */
  background: url(../img/bg-illust.png) right center no-repeat #ffffff;
  background-size: auto;
  background-clip: content-box;
  display: block;
  border-bottom: 1px solid #dcdbd7;
  position: relative;
  z-index: 99;
  box-shadow: 0 -10px 15px rgb(0 0 0 / 15%) inset;
}

.site-header {
  padding: 30px 0 0;
}

.header-image .title-area {
  padding: 0;
}

.header-image .site-header .site-title a {
  height: 65px;
  min-height: 65px;
  padding: 0;
}

.header-image .site-title a {
}

.site-title h3 {
  font-weight: 900;
  color: #2f2f2f;
}
.site-title h4 {
  font-weight: 900;
  color: #2f2f2f;
}

.callus span {
  margin-left: 10px;
  color: #ffffff;
  letter-spacing: 0.65px;
  font-weight: 300;
}

.callus p {
  margin: 8px;
  font-size: 12px;
}

.social_buttons a {
  width: 23px;
  height: 23px;
  margin: 4px 0;
  font-size: 15px;
  display: inline-block;
  text-align: center;
  margin-left: 0;
  line-height: 23px;
  color: #facf0f;
}

.title-area {
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.footer {
  background: #2b2e31;
  padding: 25px 0 0;
  position: relative;
  overflow: hidden;
  display: block;
  z-index: 1;
  box-shadow: 0px -10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.footer ul {
  margin-left: 0;
}

.footer .title {
  border-bottom: 1px solid #696e74;
}

.copyrights p {
  font-size: 12px;
  margin-top: 10px;
}

.footer-menu {
  margin: 10px 0;
}

.footer-left p {
  display: inline-block;
  color: #fff;
}

.credits {
  display: inline-block;
}

.footer-menu a {
}

.footer-menu ul {
  list-style: none;
  list-style-position: outside;
  float: right;
  display: inline;
  margin: 5px;
}

.footer-menu li {
  line-height: 12px;
  float: left;
  padding: 0 7px;
}

.footer .widget h4 {
  color: #ffffff;
}

.copyrights {
  background: #3e4246;
  padding: 0;
  font-size: 12px;
  border-top: 1px solid #3e4246;
  margin-top: 15px;
  color: #fff;
}

.copyrights a {
  color: #fff;
}

#nav {
  margin-top: 0;
}

#nav .container {
  width: inherit;
}

#nav.affix {
  position: fixed;
  top: -1px;
  left: 0;
  width: 100%;
  margin-top: 0;
  z-index: 99;
  background: rgba(255, 255, 255, 0.9);
  border-bottom: 1px solid #dcdbd7;
}

.slideshow-wrapper {
  background: #f5f5f5;
  border-top: 1px solid #e5e4e4;
}

.section4,
.section1 {
  background: #ffffff;
  padding: 30px 0;
}

.section2 {
  background: #464646;
  padding: 30px 0;
}

.section3 {
  background: #e4e4e4;
  border-top: 1px solid #e5e4e4;
  border-bottom: 1px solid #e5e4e4;
}

.section5 {
  background: #c6c6c6;
  padding: 30px 0;
}

.section5 h4 {
  color: #f2f2f2;
}

.withpadding {
  padding: 30px 0;
}

.transparent {
  background: url("../img/slides_01.jpg") no-repeat fixed center top / cover;
  padding: 0;
  color: #ffffff !important;
}

.transparent-wrap .general-title hr {
  background-color: #ffffff;
}

.videobg {
  padding: 0;
  color: #ffffff !important;
}

.videobg-wrap .general-title hr {
  background-color: #ffffff;
}

.videobg-wrap {
  background-color: rgba(43, 46, 49, 0.8);
  padding: 30px 0 0;
}

.videobg-wrap .general-title p,
.transparent-wrap .highlight,
.transparent-wrap .milestone-details,
.transparent-wrap .f-element i,
.transparent-wrap .general-title p,
.transparent-wrap h3 {
  color: #ffffff;
}

.videobg-wrap .general-title h3,
.transparent-wrap .general-title h3 {
  color: #ffffff;
  font-size: 38px;
  font-weight: 700;
  letter-spacing: -0.8px;
  text-transform: uppercase;
}

.transparent-wrap {
  padding: 30px 0;
  display: block;
  margin-bottom: 0;
  position: relative;
  overflow: hidden;
}

.general-title hr {
  border: 0 none;
  height: 1px;
  margin: 10px auto;
  width: 120px;
  background: #2b2e31;
}

.general-title h3 {
  font-weight: 600;
  letter-spacing: -0.65px;
  font-size: 31px;
  padding: 20px 0 0;
  text-transform: uppercase;
}

.title {
  position: relative;
  padding-bottom: 20px;
  margin: 20px 0;
  border-bottom: 1px solid;
}

.message {
  padding: 20px 0 !important;
  display: block;
  position: relative;
  overflow: hidden;
}

.message .small-title {
  color: #ffffff;
  margin: 30px 0 0;
  font-size: 18px;
  text-transform: uppercase;
}

.message .big-title {
  letter-spacing: -0.8px;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 38px;
  font-weight: 700;
}

.message h3 {
  text-transform: uppercase;
  font-weight: 700;
}

.message .button {
  margin-top: 30px;
}

.servicetab .tab-content {
  padding: 10px 30px;
}

.services_lists_boxes {
  margin: 0;
}

.servicebox {
  margin: 30px 0;
}

.servicebox h4 {
  display: block;
  width: 100%;
  font-size: 21px;
  letter-spacing: -0.65px;
  font-weight: 500;
}

.services_lists_boxes_icon:after {
  color: #fff;
  z-index: 90;
  font-size: 14px;
  font-family: "FontAwesome";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
}

.services_lists_boxes_icon:after {
  content: "\f175";
  text-align: center;
  display: inline-block;
  cursor: pointer;
  margin: 10px 0 0;
  width: 110px;
  text-align: center;
  position: relative;
  z-index: 1;
}

.services_lists hr {
  margin: 10px 0 !important;
}

.services_lists hr,
.servicetitle hr {
  border: 0 none;
  height: 3px;
  margin: 10px auto;
  width: 80px;
}

.checkout .quantity {
  width: 50px;
}

.theme_overviews .icon-container {
  display: block;
  float: left;
  left: 0;
  top: 20px;
  overflow: hidden;
  position: absolute;
}

.theme_overviews .services {
  padding-left: 40px;
  margin-top: 0;
  text-align: left;
}

.theme_overviews .services h3 {
  font-size: 18px;
  text-transform: none;
}

.theme_overviews .icon-container i {
  font-size: 35px;
  margin-left: 3px;
}

.theme_details .button.large {
  font-size: 14px !important;
}

.recent-portfolio-item,
.recent-blog-item {
  padding: 15px;
}

.recent-blog-item .title {
  font-size: 18px;
  margin: 15px 0;
  padding-bottom: 15px;
}

.recent-blog-items .owl-pagination,
.recent-portfolio-items .owl-pagination {
  right: 0;
  top: -55px;
  left: inherit;
}

.portfolio_category a {
  color: #ffffff;
}

.portfolio-item .title:before {
  border-radius: 0;
  display: none;
}

.portfolio-item p {
  margin: 0px 0 30px;
}

.portfolio-item h3 {
  margin: -10px 0 10px;
  font-size: 16px;
  text-transform: uppercase;
}

.portfolio_details {
  margin: 0;
  border: 0;
  border: 1px solid #ededed;
  background-color: #fff;
  padding: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  text-align: left;
  -webkit-transition: background 0.3s ease-out;
  transition: background 0.3s ease-out;
}

.theme_overviews,
.theme_details {
  margin: 0;
  border: 0;
  border: 1px solid #ededed;
  background-color: #fff;
  padding: 9px 18px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  text-align: left;
  -webkit-transition: background 0.3s ease-out;
  transition: background 0.3s ease-out;
}

.portfolio_details .details_section,
.theme_details .details_section {
  border-bottom: 1px solid #e9e9e9;
  padding: 30px 30px 10px;
}

.theme_details .details_section:last-child {
  border: 0 none;
  padding-bottom: 20px;
}

.item_price h3 span {
  color: #2b2e31;
  display: block;
  font-family: "Ruda", sans-serif;
  font-size: 41px;
  font-weight: 400;
  margin: 25px 0 0;
  text-align: center;
}

.item_price h3 span small {
  color: #2b2e31;
  font-size: 21px;
  left: 0px;
  padding: 5px;
  position: relative;
  top: 5px;
  vertical-align: top;
}

.button i {
  padding: 0 3px;
}

.portfolio_details h3,
.theme_details h3 {
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 20px;
}

.portfolio_details li {
  list-style: none;
  color: #2b2e31;
  margin-bottom: 10px;
}

.theme_details li {
  list-style: none;
  color: #2b2e31;
}

.portfolio_details li span {
  display: inline;
  padding: 5px 0 15px;
  color: #696e74;
  text-transform: none;
}

.theme_details li span {
  display: block;
  padding: 5px 0 15px;
  color: #696e74;
  text-transform: none;
}

.theme_details li span a {
  padding: 0;
}

.theme_details li a {
  color: #696e74;
  display: block;
  font-size: 13px;
  padding: 5px 0 0;
  text-transform: none;
}

.theme_details li a:hover {
  text-decoration: underline;
}

.item-description h1 {
  font-size: 31px;
  letter-spacing: -1px;
  margin-bottom: 0;
  margin-bottom: 0;
  padding: 15px 0 5px;
}

.general-title p {
  color: #696e74;
  font-family: "Ruda", sans-serif !important;
  font-size: 18px;
  font-style: italic;
  font-weight: 300;
  line-height: 1;
  padding: 10px 0;
}

.checkout td a {
  padding-left: 20px;
}

.isotope-item {
  z-index: 2;
}

.isotope-hidden.isotope-item {
  pointer-events: none;
  z-index: 1;
}

.isotope,
.isotope .isotope-item {
  -webkit-transition-duration: 0.8s;
  -moz-transition-duration: 0.8s;
  -ms-transition-duration: 0.8s;
  -o-transition-duration: 0.8s;
  transition-duration: 0.8s;
}

.isotope {
  -webkit-transition-property: height, width;
  -moz-transition-property: height, width;
  -ms-transition-property: height, width;
  -o-transition-property: height, width;
  transition-property: height, width;
}

.isotope .isotope-item {
  -webkit-transition-property: -webkit-transform, opacity;
  -moz-transition-property: -moz-transform, opacity;
  -ms-transition-property: -ms-transform, opacity;
  -o-transition-property: -o-transform, opacity;
  transition-property: transform, opacity;
}

.isotope.no-transition,
.isotope.no-transition .isotope-item,
.isotope .isotope-item.no-transition {
  -webkit-transition-duration: 0s;
  -moz-transition-duration: 0s;
  -ms-transition-duration: 0s;
  -o-transition-duration: 0s;
  transition-duration: 0s;
}

.portfolio-filter ul {
  list-style: none;
  padding-left: 10px;
}

.portfolio-filter {
  text-align: center;
  margin: 30px 0;
}

.portfolio-filter li {
  display: inline;
  margin-right: 15px;
  padding: 0;
}

.portfolio-filter li a {
  text-transform: uppercase;
}

.portfolio-wrapper {
  margin: 40px 0;
}

.owl-carousel .item a,
.owl-carousel .item a img {
  display: block;
  position: relative;
}

.owl-carousel .item a {
  overflow: hidden;
  color: #ffffff;
  cursor: crosshair;
  cursor: url("../img/cursor.png") 40 40, crosshair;
}

.owl-carousel .item a div {
  position: absolute;
  width: 100%;
  height: 100%;
}

.owl-carousel .item a div span {
  display: block;
  padding: 0 8px;
  margin: 20px 20px 0;
  font-size: 21px;
  text-align: left;
  border-left: 3px solid #ec353d;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.9);
}

.owl-carousel .item small {
  display: block;
  font-size: 14px;
  margin-bottom: 0;
  padding: 10px;
  text-transform: none;
  bottom: 0;
  left: 10px;
  position: absolute;
}

.owl-carousel .item i {
  font-size: 16px;
  height: 40px;
  text-align: center;
  width: 40px;
  bottom: 0;
  line-height: 2.4;
  border-left: 1px solid #111111;
  border-top: 1px solid #111111;
  right: 0;
  position: absolute;
}

/*	################################################################
	3. MODULES
################################################################# */

.client {
  padding: 10px 5px;
  background: #ffffff;
  margin-top: 40px;
  background-color: rgba(255, 255, 255, 0.6);
  text-align: center;
}

.client .tooltip-inner {
  padding: 10px;
}

#volume {
  bottom: 0;
  position: absolute;
  z-index: 10;
  right: 0;
  width: 30px;
  height: 30px;
  line-height: 26px;
  font-size: 20px;
  text-align: center;
  border: 1px solid #2b2e31;
  color: #ffffff;
  cursor: pointer;
}

.custom-box {
  background: #ffffff;
  border: 1px solid #dcdbd7;
  margin: 30px 0;
  padding: 30px 20px 35px;
  text-align: center;
}

.custom-box .widget-title {
  margin-top: 10px;
}

.custom-box a.readmore {
  padding: 0;
}

.icn-main-container {
  position: relative;
}

.icn-container {
  display: inline-block;
  width: 100px;
  height: 100px;
  border-radius: 500px;
  text-align: center;
  margin: 10px 0;
  font-weight: 600;
  font-size: 2.4em;
  line-height: 100px;
  color: #fff;
  -webkit-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
}

.icn-container.active,
.icn-container:hover {
  background: #2b2e31 !important;
}

.pricing {
  list-style: none;
  padding: 10px 0 20px;
}

.pricing li {
  border-bottom: 1px solid #dcdbd7;
  font-size: 13px;
  font-weight: 100;
  line-height: 34px;
  margin: 0 auto;
  padding: 0;
  text-align: center;
  width: 70%;
  -webkit-transition: background-color 0.4s linear, color 0.4s linear;
  -moz-transition: background-color 0.4s linear, color 0.4s linear;
  -o-transition: background-color 0.4s linear, color 0.4s linear;
  -ms-transition: background-color 0.4s linear, color 0.4s linear;
  transition: background-color 0.4s linear, color 0.4s linear;
}

.pricing li:last-child {
  border-bottom: 0px solid #fff;
}

.the-icons li {
  list-style: none;
}

#bbsearch.form-inline input[type="text"],
#bbsearch.form-inline input[type="password"] {
  width: 50%;
  margin-top: 20px;
}

#bbsearch .form-control {
  height: 55px;
}

#bbsearch .btn {
  padding: 14px 35px;
  font-size: 18px;
  line-height: 1.426;
  border-radius: 0;
  margin-top: 10px;
}

.highlight {
  font-size: 61px;
  font-weight: 700;
  line-height: 1;
  margin: 30px 0 0;
}

.milestone-details {
  font-size: 18px;
  color: #2b2e31;
  font-weight: 400;
  letter-spacing: -0.5px;
  padding: 10px 0 20px;
}

.f-element {
  margin-top: 40px;
  height: auto;
  text-align: center;
}

.f-element i {
  margin-bottom: 10px;
  font-size: 36px;
  color: #2b2e31;
}

.percent {
  display: block;
  font-size: 40px;
  letter-spacing: -3px;
  line-height: 210px;
  font-weight: bold;
  position: absolute;
  text-align: center;
  top: -3px;
  color: #2b2e31;
  width: 90%;
  z-index: 10;
}

.chart {
  margin-bottom: 20px;
}

.skills {
  margin: 60px 0;
}

.teammembers {
  border: 1px dashed #dddddd;
  padding: 25px;
  text-align: center;
  margin: 30px 0;
}

.teamskills {
  margin-top: 20px;
}

.teammembers img {
  margin-bottom: 10px;
}

.testimonial {
  margin: 20px 0 0;
  border: 0;
  background-color: #fff;
  padding: 15px;
  min-height: 150px;
  border-bottom: 1px dashed #dddddd;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-transition: background 0.3s ease-out;
  transition: background 0.3s ease-out;
}

.testimonial:after {
  font-family: "FontAwesome";
  content: "\f10e";
  padding-right: 5px;
  right: 0;
  position: absolute;
  top: 20px;
  font-size: 400%;
  opacity: 0.1;
  -moz-opacity: 0.1;
  filter: alpha(opacity=10);
}

.teammembers h4,
.testimonial h4 {
  font-size: 16px;
  padding-right: 5px;
}

.teammembers small,
.testimonial small {
  font-size: 11px;
  font-style: italic;
  color: #798184;
  padding: 0 10px;
  font-family: "Ruda", sans-serif !important;
  font-weight: 300;
}

.testimonial img {
  margin-right: 15px;
  width: 75px;
  border: 0 solid #ffffff !important;
  height: 75px;
  margin-top: 5px;
  border: medium none;
  display: inline-block;
  image-rendering: optimizequality;
  max-width: 100%;
}

.testimonial-meta {
  overflow: hidden;
}

.dmbox {
  margin: 0;
  border: 0;
  border: 1px solid #ededed;
  background-color: #fff;
  padding: 9px 18px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  text-align: center;
  -webkit-transition: background 0.3s ease-out;
  transition: background 0.3s ease-out;
}

.divider {
  margin: 25px 0;
  height: 2px;
  display: block;
  position: relative;
  overflow: hidden;
}

button,
.button {
  cursor: pointer;
  font-weight: normal;
  line-height: normal;
  margin: 0 0 1.25rem;
  position: relative;
  text-decoration: none;
  text-align: center;
  display: inline-block;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  padding: 0.5em 1em;
  font-size: 1.3rem;
  border-color: #696e74;
  color: white;
  -webkit-transition: background-color 300ms ease-out;
  -moz-transition: background-color 300ms ease-out;
  transition: background-color 300ms ease-out;
  -webkit-appearance: none;
  border: none;
  font-weight: normal !important;
}

button:hover,
button:focus,
.button:hover,
.button:focus {
  background-color: #3e4246;
}

button:hover,
button:focus,
.button:hover,
.button:focus {
  color: white;
}

button.large,
.button.large {
  padding-top: 1.125rem;
  padding-right: 2.25rem;
  padding-bottom: 1.1875rem;
  padding-left: 2.25rem;
  font-size: 1.55rem;
}

button.small,
.button.small {
  padding-top: 0.575rem;
  padding-right: 1.75rem;
  padding-bottom: 0.6375rem;
  padding-left: 1.75rem;
  font-size: 1.3125rem;
}

.dmbutton:hover,
.dmbutton:active,
.dmbutton:focus {
  color: #ffffff;
  background-color: #222222;
  border-color: #ffffff;
}

.dmbutton {
  background: rgba(0, 0, 0, 0);
  border: 1px solid #ffffff;
  color: #ffffff;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  padding-top: 1.025rem;
  padding-right: 2.25rem;
  letter-spacing: 0.85px;
  padding-bottom: 1.0875rem;
  padding-left: 2.25rem;
  font-size: 1.55rem;
  cursor: pointer;
  font-weight: normal;
  line-height: normal;
  margin: 0 0 1.25rem;
  text-decoration: none;
  text-align: center;
  display: inline-block;
  -webkit-transition: background-color 300ms ease-out;
  -moz-transition: background-color 300ms ease-out;
  transition: background-color 300ms ease-out;
  -webkit-appearance: none;
  font-weight: normal !important;
}

.dmbutton2:hover,
.dmbutton2:active,
.dmbutton2:focus {
  color: #2b2e31;
  background-color: #ffffff;
  border-color: #2b2e31;
}

.dmbutton2 {
  background: rgba(0, 0, 0, 0);
  border: 1px solid #2b2e31;
  color: #2b2e31;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  padding-top: 0.75rem;
  padding-right: 1.25rem;
  letter-spacing: 0.25px;
  padding-bottom: 0.95rem;
  padding-left: 1.25rem;
  font-size: 1.22rem;
  cursor: pointer;
  font-weight: 300 !important;
  line-height: normal;
  margin: 0 0 1.25rem;
  text-decoration: none;
  text-align: center;
  display: inline-block;
  -webkit-transition: background-color 300ms ease-out;
  -moz-transition: background-color 300ms ease-out;
  transition: background-color 300ms ease-out;
  -webkit-appearance: none;
  font-weight: normal !important;
}

.no-touch .dm-icon-effect-1 .dm-icon:hover {
  color: #ffffff;
}

.no-touch .dm-icon-effect-1 .dm-icon.active {
}

.dm-icon-medium {
  width: 80px !important;
  height: 80px !important;
  margin: 25px 15px !important;
  background: #3e4246 !important;
}

.services_lists_boxes_icon_none i:hover,
.services_lists_boxes_icon i:hover {
  background: #ffffff;
  -webkit-transition: background 0.2s, color 0.2s;
  -moz-transition: background 0.2s, color 0.2;
  transition: background 0.2s, color 0.2s;
}

.dm-icon-medium,
.dm-icon {
  display: inline-block;
  cursor: pointer;
  margin: 15px;
  width: 105px;
  height: 105px;
  border-radius: 50%;
  text-align: center;
  position: relative;
  z-index: 1;
  background: #2b2e31;
  color: #ffffff;
}

.dm-icon-medium:after,
.dm-icon:after {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: "";
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.dm-icon-medium:before {
  line-height: 80px !important;
}

.dm-icon-medium:before,
.dm-icon:before {
  speak: none;
  line-height: 105px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  display: block;
  -webkit-font-smoothing: antialiased;
}

.dm-icon-effect-1 .dm-icon {
  -webkit-transition: background 0.2s, color 0.2s;
  -moz-transition: background 0.2s, color 0.2;
  transition: background 0.2s, color 0.2s;
}

.dm-icon-effect-1 .dm-icon:after {
  top: -7px;
  left: -7px;
  padding: 7px;
  -webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
  -webkit-transform: scale(0.8);
  -moz-transition: -moz-transform 0.2s, opacity 0.2s;
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transition: transform 0.2s, opacity 0.2s;
  transform: scale(0.8);
  opacity: 0;
}

.no-touch .dm-icon-effect-1 .dm-icon:hover:after {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

[class*=".effect-"] {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  perspective: 1000;
}

.effect-fade {
  opacity: 0;
}

.effect-fade.in {
  opacity: 1;
}

.effect-slide-left {
  -webkit-transform: translate3d(-30%, 0, 0);
  transform: translate3d(-30%, 0, 0);
  opacity: 0;
}

.effect-slide-left.in {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.effect-slide-right {
  -webkit-transform: translate3d(30%, 0, 0);
  transform: translate3d(30%, 0, 0);
  opacity: 0;
}

.effect-slide-right.in {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.effect-slide-top {
  -webkit-transform: translate3d(0, -30%, 0);
  transform: translate3d(0, -30%, 0);
  opacity: 0;
}

.effect-slide-top.in {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.effect-slide-bottom {
  -webkit-transform: translate3d(0, 30%, 0);
  transform: translate3d(0, 30%, 0);
  opacity: 0;
}

.effect-slide-bottom.in {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.effect-pop {
  opacity: 0;
}

.effect-pop.in {
  -webkit-animation: pop 0.6s ease-out 0.5s both;
  -moz-animation: pop 0.6s ease-out 0.5s both;
  -o-animation: pop 0.6s ease-out 0.5s both;
  animation: pop 0.6s ease-out 0.5s both;
  opacity: 1 \9;
}

@-webkit-keyframes pop {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

@-moz-keyframes pop {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

@-o-keyframes pop {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes pop {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

.effect-fall {
  opacity: 0;
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  transform: scale(1.5);
}

.effect-fall.in {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

.effect-perspective {
  -webkit-perspective: 1300px;
  -moz-perspective: 1300px;
  perspective: 1300px;
}

.effect-helix {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  opacity: 0;
  -webkit-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}

.effect-helix.in {
  opacity: 1;
  -webkit-transform: rotateY(0);
  -ms-transform: rotateY(0);
  transform: rotateY(0);
}

.tpl1 .sider-right {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 45px;
  background-color: #fefefe;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
}

.tpl1 .sider-right a {
  display: block;
  height: 50px;
  margin-top: 13px;
  background-position: center center;
  background-repeat: no-repeat;
}

.tpl1 .zoom {
  background-image: url("../img/zoom.png");
}

.tpl1 .undo {
  background-image: url("../img/undo.png");
}

.tpl1 .check {
  background-image: url("../img/check.png");
}

.tpl1 .close {
  background-image: url("../img/close.png");
}

.tpl1 a.zoom:hover {
  background-image: url("../img/zoom-hover.png");
}

.tpl1 a.close:hover {
  background-image: url("../img/close-hover.png");
}

.tpl1 a.undo:hover {
  background-image: url("../img/undo-hover.png");
}

.tpl1 a.check:hover {
  background-image: url("../img/check-hover.png");
}

/*template 2*/

.tpl2 .bg {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100%;
}

.tpl2 .center-bar {
  height: 32px;
  position: absolute;
  top: 50%;
  margin-top: -16px;
  text-align: center;
  width: 100%;
}

.tpl2 .center-bar a {
  display: inline-block;
  width: 32px;
  height: 32px;
  margin: 0 3px;
}

.tpl2 .twitter {
  background: url("../img/twitter.png") no-repeat center center;
}

.tpl2 a.twitter:hover {
  background: url("../img/twitter-hover.png") no-repeat center center;
}

.tpl2 .in {
  background: url("../img/in.png") no-repeat center center;
}

.tpl2 a.in:hover {
  background: url("../img/in-hover.png") no-repeat center center;
}

.tpl2 .facebook {
  background: url("../img/facebook.png") no-repeat center center;
}

.tpl2 a.facebook:hover {
  background: url("../img/facebook-hover.png") no-repeat center center;
}

.tpl2 .google {
  /* background: url("../img/google.png") no-repeat center center; */
}

.tpl2 a.google:hover {
  /* background: url("../img/google-hover.png") no-repeat center center; */
}

/*template 3*/

.tpl3 .info-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fefefe;
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  padding: 10px;
  text-align: center;
  font-size: 14px;
  color: #333;
  text-shadow: 1px 1px 0 #fff;
}

.tpl3 .info-top {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  background-color: #fefefe;
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 0 3px rgba(0, 0, 0, 0.5);
  padding: 10px;
  text-align: center;
  font-size: 14px;
  color: #333;
  text-shadow: 1px 1px 0 #fff;
}

.tpl3 .info-fly {
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background-color: #fefefe;
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  padding: 10px;
  text-align: center;
  font-size: 14px;
  color: #333;
  text-shadow: 1px 1px 0 #fff;
}

.tpl4 .bg,
.tpl4 .content {
  position: absolute;
  width: 100%;
  height: 100%;
}

.tpl4 .bg div {
  width: 20%;
  height: 100%;
  display: inline-block;
  *zoom: 1;
  *display: inline;
  background-color: #aaa;
  background-color: rgba(0, 0, 0, 0.4);
}

.tpl4 .content .info-title {
  color: #fefefe;
  font-size: 18px;
  margin: 0;
  padding: 10px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  background-color: #999;
  background-color: rgba(0, 0, 0, 0.3);
}

.tpl4 .content .detail {
  padding: 8px 10px;
  font-size: 14px;
  line-height: 1.5em;
  color: #fdfdfd;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}

.tpl4 .content .more {
  text-decoration: none;
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 13px;
  padding: 5px 8px;
  color: #fff;
  background-color: #fff;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px 0 0 0;
}

.tpl5 .price {
  border-radius: 100%;
  font-size: 30px;
  font-weight: bold;
  color: #fff;
  background: #c00;
  line-height: 60px;
  text-align: center;
  padding: 15px;
  display: block;
  position: absolute;
  text-decoration: none;
  bottom: 10px;
  right: 10px;
  width: 60px;
  height: 60px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.tpl5 .buy {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  position: absolute;
  top: 40px;
  left: 110px;
  background: #c00;
  border-radius: 4px;
  padding: 8px 12px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.owl-carousel .item a div,
.tpl6 .bg {
  height: 100%;
  width: 100%;
  background-color: #2b2e31;
  background-color: rgba(43, 46, 49, 0.7);
  text-align: center;
}

.he-view .rating i {
  color: #ffffff;
}

.portfolio {
  padding: 0 !important;
  margin: 0 !important;
  display: block;
}

.market-item {
  padding: 0 !important;
  margin: 0 0 30px 0 !important;
  border: 1px solid #f5f5f5;
}

.tpl6 .big {
  color: #ffffff;
  font-size: 25px;
  margin: 0;
  padding: 90px 5px 0;
  text-transform: uppercase;
}

.tpl6 .small {
  color: #ffffff;
  font-size: 16px;
  margin: 0;
  padding: 40px 5px 0;
  text-transform: uppercase;
}

.tpl6 h3 {
  color: #fff;
  margin: 0;
  padding: 40px 5px 0;
  font-size: 16px;
  text-transform: uppercase;
}

.tpl6 .dmbutton {
  display: inline-block;
  margin: 30px 5px 20px 5px;
  font-size: 13px;
}

.dmtop {
  background-image: url("../img/arrow.png");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  z-index: 9999;
  width: 40px;
  height: 40px;
  text-indent: -99999px;
  position: fixed;
  bottom: -100px;
  right: 25px;
  cursor: pointer;
  -webkit-transition: all 0.7s ease-in-out;
  -moz-transition: all 0.7s ease-in-out;
  -o-transition: all 0.7s ease-in-out;
  -ms-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
  overflow: auto;
}

.dmtop:hover {
  background-color: #3e4246;
}

.js-video {
  height: 0;
  padding-top: 25px;
  padding-bottom: 50.5%;
  border: 1px solid #ffffff;
  position: relative;
  overflow: hidden;
}

.js-video.widescreen {
  padding-bottom: 57.25%;
  border: 1px solid #ffffff;
}

.js-video.vimeo {
  padding-top: 0;
  border: 1px solid #ffffff;
}

.google-map {
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.5);
  min-height: 200px;
}

div.google-map {
  background: rgba(255, 255, 255, 0.5);
  background: #ffffff;
  height: 400px;
  margin: 0 0 0px 0;
  width: 100%;
}

.js-video embed,
.js-video iframe,
.js-video object,
.js-video video {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

/*	################################################################
	CUSTOM
################################################################# */
.jetmenu > li > a {
  font-size: 16px;
  border-top: 3px solid transparent;
  color: #000;
}
.jetmenu ul.dropdown li a {
  font-size: 14px;
}

/*	################################################################
	4. SIDEBARS AND WIDGETS
################################################################# */

#sidebar {
  padding: 20px 35px;
}

#sidebar li {
  list-style: none;
  margin-left: 10px;
  margin-bottom: 5px;
}

#sidebar .nav-tabs li {
  margin-left: 0;
  margin-bottom: 0;
}

#sidebar a {
  color: #696e74;
}

#sidebar .tabbable .recent_posts li {
  margin-top: 15px;
}

#sidebar .widget {
  padding-top: 0;
  padding-bottom: 35px;
}

#sidebar .recent_posts img,
.flickr img {
  border: 1px solid #f0f0f0;
}

.content {
  padding: 20px 20px 60px 20px;
}

.widget {
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 20px;
}

.widget h3,
.widget h4 {
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0;
}

#post-tags a,
#sidebar .tagcloud a {
  float: left;
  display: block;
  margin-bottom: 4px;
  margin-right: 4px;
  padding: 5px 10px;
  font-size: 12px !important;
  line-height: 1.4em !important;
  color: #fff !important;
  text-decoration: none;
  font-weight: 300;
  letter-spacing: 1px;
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

#post-tags a:hover,
#sidebar .tagcloud a:hover {
  background: #3e4246 !important;
  color: #fff !important;
  text-decoration: none;
  -webkit-transition: all 0.01s ease-out;
  -moz-transition: all 0.01s linear;
  transition: all 0.01s linear;
}

#sidebar #subscribe .form-control {
  margin-bottom: 10px;
}

.recent_posts > li {
  display: table;
  height: 60px;
  margin-bottom: 10px;
  width: 100%;
}

.recent_posts > li:last-child {
  border-bottom: 0 solid #fff;
}

.section2 .widget h4 {
  color: #ffffff;
}

.footer ul li,
.recent_posts li {
  list-style: none;
}

.recent_posts a {
  color: #696e74;
}

.recent_posts > li img {
  max-width: 60px;
}

a.readmore {
  text-transform: none;
  display: block;
  font-family: "Ruda", sans-serif !important;
  font-size: 12px;
  font-style: italic;
}

.recent_posts img {
  float: left;
  margin: 0 15px 0 0;
}

.comment img,
.author_box img,
.checkout img,
.testimonial img,
.recent_posts img,
.flickr img {
  border: 1px solid #3e4246;
  -webkit-transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out;
  -ms-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}

.comment img:hover,
.author_box img:hover,
.checkout img:hover,
.testimonial img:hover,
.recent_posts img:hover,
.flickr img:hover {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=70);
  -moz-opacity: 0.7;
  -khtml-opacity: 0.7;
  opacity: 0.7;
}

.flickr li {
  list-style: none;
  float: left;
  margin: 5px;
}

.flickr li img {
  width: 50px;
  height: 50px;
}

.contact_details li {
  padding: 0 0 5px 0;
  list-style: none;
}

.pricing li:before,
.check li:before {
  content: "\f00c";
  font-family: "FontAwesome";
  font-size: 16px;
  left: 0;
  padding-right: 5px;
  position: relative;
  top: 2px;
}

.check li {
  list-style: none;
  margin: 5px 0;
}

.check {
  margin-left: 0;
  padding-left: 0;
}

.check li a {
  color: #696e74;
}

@media (max-width: 768px) {
  .notfound {
    background: none;
  }
  .notfound .small-title {
    width: 100%;
  }
  .callus,
  .search-bar,
  .post-wrapper-top,
  .title-area,
  .social_buttons {
    float: none !important;
    clear: both !important;
    display: block !important;
    text-align: center !important;
  }
}

@media only screen and (min-width: 40.063em) {
  button,
  .button {
    display: inline-block;
  }
}

/* Contact Form */

#contact {
  padding: 40px 0;
}

.contact-form {
}

.contact-form label {
  color: #999;
}

.contact-form input,
.contact-form textarea {
  padding: 10px 12px;
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  height: auto;
}

.contact-form .form-send {
  text-align: center;
}

.contact-form .validate {
  display: none;
  color: red;
  margin: 0 0 15px 0;
  font-weight: 400;
  font-size: 13px;
}

.contact-form .loading {
  display: none;
  color: #555;
  background: #fff;
  text-align: center;
  padding: 15px;
  margin: 15px 0;
}

.contact-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

@-webkit-keyframes animate-loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.contact-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin: 15px 0;
}

.contact-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin: 15px 0;
}

section {
  overflow: hidden;
}

#collasible-nav-dropdown {
  color: black;
}

#collasible-nav-dropdown::after {
  display: none;
  color: white;
}

#page-wrap {
  text-align: center;
  overflow: auto;
  top: 45%;
  position: relative;
}

.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

.bm-item:hover {
  color: white;
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: white;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.menu-item {
  padding: 5px;
  color: black;
  outline: none;
}

.menu-item:hover {
  background-color: transparent;
}

#maplocation {
  height: 500px;
}

#mapid {
  height: 100%;
  width: 100%;
}

.leaflet-container {
  width: 100%;
  height: 100%;
}

.div-link {
  cursor: pointer;
  color: #337ab7;
}

.div-link:hover {
  text-decoration: underline;
}

.my-modal {
  width: 26vw;
  max-width: 26vw;
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  .my-modal {
    width: 100vw;
    max-width: 100vw;
  }
}